export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Enero 2024',
    headline: 'Congreso De La Palabra',
    description: 'Jueves 25 - Domingo 28',
    buttonLabel: '* CONGRESO *',
    imgStart: false,
    img: "https://github.com/jcrommar/dallas-gerizim/blob/main/public/images/congreso_2024.jpg?raw=true",
    alt: 'Ministerio',
    dark: false,
    primary: false,
    darkText: true
};
