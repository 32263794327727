export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '- Apóstol -',
    topLine2: '- Pastora -',
    headline: 'Raúl Martínez | ',
    headline2: 'Rebeca García | ',
    description: 'Año De La Recompensa',
    description2: '2024 | Ministerios Ebenezer',
    buttonLabel: 'Dirección',
    imgStart: false,
    img: "https://github.com/jcrommar/dallas-gerizim/blob/gh-pages/images/ApostolPastora4345_BK.png?raw=true",
    alt: 'pastores',
    img2: "https://github.com/jcrommar/dallas-gerizim/blob/main/public/images/Gerizim2021LOGO_AllWHITE.png?raw=true",
    alt2: 'ministerios ebenezer dallas',
    dark: true,
    primary: true,
    darkText: false
};
