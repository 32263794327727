export const socialObj = {
    id: 'facebook',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Ministerios Ebenezer Dallas',
    headline: 'Comparte',
    buttonLabel: 'Mas Información',
    imgStart: false,
    dark: false,
    primary: false,
    darkText: true
};
